import React, { Component } from 'react';
import TopNav from "./TopNav";
import {Link} from "react-router-dom";
import Swish from '../images/swish_bw.png';
import {Transition} from "react-transition-group";
import Rubrik from '../images/rubrik_info.png';

class Presenter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasMounted: false,
    };
  }

  componentDidMount() {
    this.setState(() => ({
      hasMounted: true,
    }));
  }

  EnterAnimation = () => {
  };

  render() {
    return (
      <Transition in={this.state.hasMounted} timeout={0} onEntered={this.EnterAnimation}>
        <div className={'presenter'}>
          <TopNav/>
          <div className={'page-content'}>
            <img src={Rubrik} className={'rubrik'} alt={'Info'} />
            <div className={'navlinks'}>
              <Link to={'anmalan'}>Anmälan</Link>
              <Link to={'hitta'}>Hitta</Link>
            </div>

            <div className={'desc'}>
              <h2>Om kvällen</h2>
              Det är öppet hus så man kan komma när man vill, men Farre är
              på plats från 18.00. Det blir alltså ingen sittning, ingen speciell
              klädsel osv. Bara hänga, ha kul och dricka öl ihop.
            </div>

            <div className={'desc'}>
              <h2>Presenter</h2>
              <p>Farre är en enkel och anspråkslös man som inte önskar sig så
                mycket presenter i livet. Han brukar önska sig tre saker; en
                flaska Ardbeg (Uigeadail), en Fred Perry och Layrite. Men vi vill
                ge honom något extra och oväntat! Kanske en resa? Kanske en
                danskurs? En Vespa?</p>

              <p>Vill du vara med?</p>

                <p>OBS! Inga blommor!</p>

              <p>Swisha till Ninas nummer <a href={'swish://0707970103'}>070-797 01 03</a><br/>Ange ditt namn tack :)</p>
              <p>Senast den 1 maj 2019</p>

              {/*<p className={'swish'}><img src={Swish} alt={'Swish QR-kod'} /></p>*/}
            </div>
          </div>
        </div>
      </Transition>
    )
  }
}

export default Presenter;
